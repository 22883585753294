import { Box, Button, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import {
  AddPersonnelForm,
  PersonnelTable,
  UserCard,
} from '../../../../components';
import { PlusIcon } from '../../../../assets';
import { IWorkItem } from '../../../../types';
import { useFetchPersonnelByWorkItem } from '../../../../hooks';
import { useGetUser } from '../../../../contexts';
import { isAdminUser } from '../../../../utils';

interface IProps {
  workItem: IWorkItem;
}

export default function PersonnelTab({ workItem }: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [personnel] = useFetchPersonnelByWorkItem(workItem.id);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);

  return (
    <Box>
      {isAdmin && (
        <AddPersonnelForm
          isOpen={isOpen}
          onClose={onClose}
          workItem={workItem}
        />
      )}

      {isAdmin && (
        <Box display="flex" justifyContent="flex-end" mb={6}>
          <Button
            leftIcon={<PlusIcon />}
            variant="primary"
            onClick={onOpen}
            size="sm"
          >
            Add Teammate
          </Button>
        </Box>
      )}

      {isMobile && (
        <Box>
          {personnel.map((person) => (
            <Box key={person.uid} mb={4}>
              <UserCard hasBorder user={person} />
            </Box>
          ))}
        </Box>
      )}

      {!isMobile && (
        <PersonnelTable
          personnel={personnel}
          showRemoveAction={isAdmin}
          showSearch={false}
          workItem={workItem}
        />
      )}
    </Box>
  );
}
