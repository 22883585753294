import { useMemo, useState } from 'react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import WorkItemBreadcrumbs from '../WorkItemBreadcrumbs';
import WorkItemsGroup from './WorkItemsGroup';
import SearchInput from './SearchInput';
import { groupWorkItemsByStatus } from './utils';
import { CreateWorkItemForm, EmptyState, Loader } from '../../../components';
import { PlusIcon } from '../../../assets';
import { useFetchWorkItems } from '../../../hooks';
import { useGetUser } from '../../../contexts';
import { isAdminUser } from '../../../utils';
import { ItemStatus } from '../../../types';

export default function WorkItemsList(): JSX.Element {
  const { id } = useParams();
  const [workItems, isLoading] = useFetchWorkItems(id);
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>('');
  const { inProgress, notStarted, suspended, complete } = useMemo(
    () => groupWorkItemsByStatus(workItems, searchValue),
    [searchValue, workItems],
  );

  const showEmptyState = !isLoading && workItems.length === 0;

  return (
    <Box>
      <CreateWorkItemForm isOpen={isOpen} onClose={onClose} projectId={id!} />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <WorkItemBreadcrumbs projectId={id} />

        <Box display="flex" alignItems="center" gap={2}>
          <SearchInput onChange={setSearchValue} />

          {isAdmin && (
            <Button
              leftIcon={<PlusIcon />}
              variant="primary"
              size="sm"
              onClick={onOpen}
              flexShrink={0}
            >
              Create Work Item
            </Button>
          )}
        </Box>
      </Box>

      <Box>
        {isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Loader />
          </Box>
        )}

        {showEmptyState && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <EmptyState
              description="Create a work item to begin work on this project."
              heading="No Work Items"
            />
          </Box>
        )}

        {workItems.length > 0 && (
          <>
            <WorkItemsGroup
              status={ItemStatus.IN_PROGRESS}
              workItems={inProgress}
            />
            <WorkItemsGroup
              status={ItemStatus.NOT_STARTED}
              workItems={notStarted}
            />
            <WorkItemsGroup
              status={ItemStatus.SUSPENDED}
              workItems={suspended}
            />
            <WorkItemsGroup status={ItemStatus.COMPLETE} workItems={complete} />
          </>
        )}
      </Box>
    </Box>
  );
}
