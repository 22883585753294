import { UserRole } from '../../types';

export default function getRoleName(role: UserRole) {
  switch (role) {
    case UserRole.ADMIN:
      return 'Assistant Coach';
    case UserRole.OWNER:
      return 'Head Coach';
    case UserRole.PERSONNEL:
      return 'Player';
    default:
      return 'User';
  }
}
