import { memo } from 'react';
import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import {
  CreateProjectForm,
  EmptyState,
  Loader,
  PageLayout,
} from '../../components';
import ProjectCard from './ProjectCard';
import { useFetchProjectAssignments } from './utils';
import { useGetUser } from '../../contexts';
import { isAdminUser } from '../../utils';
import { PlusIcon } from '../../assets';

function ProjectsPage(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projects, isLoading] = useFetchProjectAssignments();
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);

  return (
    <PageLayout>
      {isAdmin && <CreateProjectForm isOpen={isOpen} onClose={onClose} />}

      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <Heading fontWeight="medium" fontSize="xl">
            Projects
          </Heading>

          {isAdmin && (
            <Button
              leftIcon={<PlusIcon />}
              variant="primary"
              size="sm"
              onClick={onOpen}
            >
              Create Project
            </Button>
          )}
        </Box>

        {isLoading ? (
          <Loader />
        ) : (
          <Box
            display="grid"
            gridTemplateColumns={['1fr', '1fr 1fr 1fr 1fr']}
            gap={8}
            flexWrap="wrap"
          >
            {projects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </Box>
        )}

        {!isLoading && projects.length === 0 && (
          <EmptyState
            description="Create a project to get started with Keelblok."
            heading="No Projects"
          />
        )}
      </Box>
    </PageLayout>
  );
}

export default memo(ProjectsPage);
