import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { UserStatus } from '../types';

export async function updateUserStatus(
  uid: string,
  status: UserStatus,
): Promise<void> {
  const userDoc = doc(db, `users/${uid}`);

  await updateDoc(userDoc, {
    status,
  });
}

export async function updateUserProjects(uid: string, projectIds: string[]) {
  const userDoc = doc(db, `users/${uid}`);

  await updateDoc(userDoc, {
    projects: projectIds,
  });
}
