import { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import WorkItemSelect from './WorkItemSelect';
import { createActivity } from '../../../../api';
import { useGetUser } from '../../../../contexts';

interface IProps {
  initialWorkItemId?: string;
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
}

export default function ActivityFormDrawer({
  initialWorkItemId,
  isOpen,
  onClose,
  projectId,
}: IProps): JSX.Element {
  const [value, setValue] = useState<string>('');
  const [workItemId, setWorkItemId] = useState<string | null>(
    initialWorkItemId || null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();
  const user = useGetUser();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const data = {
        createdBy: user.uid,
        description: value,
        projectId,
        workItemId: workItemId,
      };

      await createActivity(data);

      toast({
        title: 'Success',
        description: 'Work item activity created.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);

      toast({
        title: 'Uh Oh',
        description: 'An error occurred while creating the work item activity.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Activity</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <WorkItemSelect
              onChange={setWorkItemId}
              projectId={projectId}
              value={workItemId}
            />

            <FormControl isRequired mb={4}>
              <FormLabel>Message</FormLabel>
              <Textarea
                placeholder="Message"
                onChange={({ currentTarget }) => setValue(currentTarget.value)}
              />
            </FormControl>

            <Box display="flex" gap={3} justifyContent="flex-end">
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isLoading}>
                Submit
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
