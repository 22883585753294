import { memo } from 'react';
import { Box, Button, FormLabel } from '@chakra-ui/react';
import { EmptyState } from '../../../components';
import { IProject } from '../../../types';

interface Props {
  onRemoveProject: (projectId: string) => void;
  projects: IProject[];
}

function CurrentProjectAssignments({
  onRemoveProject,
  projects,
}: Props): JSX.Element {
  return (
    <Box w="full">
      <FormLabel>Current Project Assignments</FormLabel>

      {projects.length > 0 && (
        <Box display="grid">
          {projects.map(({ id, name }) => (
            <Box
              key={id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid"
              borderColor="gray.200"
              px={2}
              py={3}
            >
              <Box>{name}</Box>
              <Box>
                <Button
                  onClick={() => onRemoveProject(id)}
                  size="xs"
                  colorScheme="red"
                >
                  Remove
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {projects.length === 0 && (
        <EmptyState
          heading="No Projects"
          description="This user is not assigned to any projects."
          width="full"
        />
      )}
    </Box>
  );
}

export default memo(CurrentProjectAssignments);
