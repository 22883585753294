import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { useFetchWorkItems } from '../../../../hooks';

interface Props {
  onChange: (workItemId: string | null) => void;
  projectId: string;
  value: string | null;
}

export default function WorkItemSelect({
  onChange,
  projectId,
  value,
}: Props): JSX.Element {
  const [workItems, isLoading] = useFetchWorkItems(projectId);

  function handleChange({
    currentTarget,
  }: React.ChangeEvent<HTMLSelectElement>) {
    onChange(currentTarget.value || null);
  }

  return (
    <FormControl mb={4}>
      <FormLabel>Work Item</FormLabel>
      <Select
        disabled={isLoading}
        onChange={handleChange}
        placeholder={isLoading ? 'Loading...' : 'Select Work Item'}
        value={value || ''}
      >
        {workItems.map((workItem) => (
          <option key={workItem.id} value={workItem.id}>
            {workItem.number}
          </option>
        ))}
      </Select>
      <FormHelperText>
        Optionally select a work item associated with this update.
      </FormHelperText>
    </FormControl>
  );
}
