import { Box, Tab, Tabs, TabPanel, TabPanels, TabList } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components';
import Header from './Header';
import PersonnelTab from './PersonnelTab';
import ResourcesTab from './ResourcesTab';
import WorkBreakdownTab from './WorkBreakdownTab';
import { useSubscribeToWorkItem } from '../../../hooks';

export default function WorkItemPage(): JSX.Element {
  const { id: projectId, workItemId } = useParams();
  const [workItem, isLoading] = useSubscribeToWorkItem(workItemId);

  return (
    <Box>
      <Header projectId={projectId!} workItem={workItem} />

      {isLoading ? (
        <Box>
          <Loader />
        </Box>
      ) : (
        <Box bg="white" rounded="md" shadow="md" p={[2, 4]}>
          <Tabs colorScheme="black">
            <TabList borderBottom="none" mb={4}>
              <Tab fontSize="sm" fontWeight="medium">
                Work Breakdown
              </Tab>
              <Tab fontSize="sm" fontWeight="medium">
                Resources
              </Tab>
              <Tab fontSize="sm" fontWeight="medium">
                Teammates
              </Tab>
            </TabList>

            {workItem && (
              <TabPanels px={1}>
                <TabPanel p={0}>
                  <WorkBreakdownTab workItem={workItem} />
                </TabPanel>
                <TabPanel p={0}>
                  <ResourcesTab workItem={workItem} />
                </TabPanel>
                <TabPanel p={0}>
                  <PersonnelTab workItem={workItem} />
                </TabPanel>
              </TabPanels>
            )}
          </Tabs>
        </Box>
      )}
    </Box>
  );
}
