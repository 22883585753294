import { memo } from 'react';
import { MenuItem, Text, useDisclosure } from '@chakra-ui/react';
import { ProjectAssignmentsFormDrawer } from '../../../../components';
import { Edit } from '../../../../assets';
import { AdminUser, PersonnelUser } from '../../../../types';

interface Props {
  user: AdminUser | PersonnelUser;
}

function ProjectAssignments({ user }: Props): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && (
        <ProjectAssignmentsFormDrawer
          isOpen={isOpen}
          onClose={onClose}
          user={user}
        />
      )}

      <MenuItem alignItems="center" display="flex" gap={2} onClick={onOpen}>
        <Edit />
        <Text>Project Assignments</Text>
      </MenuItem>
    </>
  );
}

export default memo(ProjectAssignments);
