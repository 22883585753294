import { Box, FormLabel, Skeleton, Text } from '@chakra-ui/react';
import WorkItemLink from './WorkItemLink';
import { UserAvatar } from '../../../../components';
import { useFetchUser } from '../../../../hooks';
import { Activity } from '../../../../types';
import dayjs from 'dayjs';

interface Props {
  activity: Activity;
}

export default function ActivityRow({ activity }: Props): JSX.Element {
  const { createdAt, createdBy, description, workItemId } = activity;
  const [user, isLoading] = useFetchUser(createdBy);
  const formattedDate = dayjs(createdAt.toDate()).format(
    'MM/DD/YYYY [at] h:mm A',
  );

  return (
    <Box>
      <Box
        alignItems="center"
        display="flex"
        fontSize="xs"
        gap={1}
        mb={2}
        textColor="gray.500"
      >
        <Skeleton isLoaded={!isLoading}>
          {user && (
            <Box alignItems="center" display="flex" gap={1}>
              <UserAvatar user={user} size="2xs" />
              <Text ml={1}>
                {user.firstName} {user.lastName}
              </Text>
            </Box>
          )}
        </Skeleton>
        <Text>on {formattedDate}</Text>
      </Box>
      <Box borderLeft="1px solid" borderColor="gray.300" ml={2} pl={4}>
        {workItemId && (
          <Box mb={2}>
            <WorkItemLink
              projectId={activity.projectId}
              workItemId={workItemId}
            />
          </Box>
        )}

        <FormLabel fontSize="2xs" mb={0.5} textColor="gray.500">
          Message
        </FormLabel>
        <Text fontSize="sm">{description}</Text>
      </Box>
    </Box>
  );
}
