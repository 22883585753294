import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { getDownloadURL, ref } from 'firebase/storage';
import { AnchorIcon } from '../../assets';
import UpdateImageButton from './UpdateImageButton';
import { storage } from '../../firebase';

interface IProps {
  isEditable?: boolean;
  projectId: string;
}

export default function ProjectImage({
  isEditable = false,
  projectId,
}: IProps): JSX.Element {
  const [projectImageUrl, setProjectImageUrl] = useState<string>('');

  useEffect(() => {
    async function fetchProjectImage() {
      try {
        const imageRef = ref(storage, `projects/${projectId}`);

        const url = await getDownloadURL(imageRef);

        setProjectImageUrl(url);
      } catch {}
    }

    fetchProjectImage();
  }, [projectId]);

  if (projectImageUrl) {
    return (
      <Box
        backgroundPosition="center"
        backgroundImage={projectImageUrl}
        backgroundSize="cover"
        h={150}
        m="auto"
        maxW={200}
        position="relative"
        rounded="md"
      >
        {isEditable && (
          <UpdateImageButton
            onUpdate={setProjectImageUrl}
            projectId={projectId}
          />
        )}
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      bg="gray.200"
      display="flex"
      h={150}
      justifyContent="center"
      mb={4}
      position="relative"
      rounded="md"
    >
      <AnchorIcon fontSize="2xl" color="gray.500" />
      {isEditable && (
        <UpdateImageButton
          color="navyBlue"
          onUpdate={setProjectImageUrl}
          projectId={projectId}
        />
      )}
    </Box>
  );
}
