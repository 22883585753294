import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Table,
  Text,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  TableContainer,
  useToast,
} from '@chakra-ui/react';
import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import PersonnelTableSearch from './PersonnelTableSearch';
import PersonnelAssignment from './PersonnelAssignment';
import PersonnelWorkItemAssignment from './PersonnelWorkItemAssignment';
import {
  EditPersonnelForm,
  PersonnelAvatar,
  TradeBadge,
  UserStatusBadge,
} from '../';
import OptionsMenu from '../UserTables/OptionsMenu';
import { filterPersonnel } from './utils';
import { Edit } from '../../assets';
import { PersonnelUser, IWorkItem, LeadTrade } from '../../types';
import { db } from '../../firebase';
import { useGetUser } from '../../contexts';
import { isAdminUser } from '../../utils';

interface IProps {
  personnel: PersonnelUser[];
  showAssignment?: boolean;
  showEditAction?: boolean;
  showRemoveAction?: boolean;
  showSearch?: boolean;
  workItem?: IWorkItem;
  workItems?: IWorkItem[];
}

export default function PersonnelTable({
  personnel,
  showAssignment = false,
  showEditAction = false,
  showRemoveAction = false,
  showSearch = true,
  workItem,
  workItems,
}: IProps): JSX.Element {
  const [editPersonnel, setEditPersonnel] = useState<
    PersonnelUser | undefined
  >();
  const [filteredPersonnel, setFilteredPersonnel] =
    useState<PersonnelUser[]>(personnel);
  const toast = useToast();
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);

  useEffect(() => {
    setFilteredPersonnel(personnel);
  }, [personnel]);

  function handleSearch(searchValue: string, tradeFilter?: LeadTrade) {
    const filteredPersonnel = filterPersonnel({
      personnel,
      searchValue,
      tradeFilter,
    });

    setFilteredPersonnel(filteredPersonnel);
  }

  async function handleRemove(uid: string): Promise<void> {
    const personnelRef = doc(db, `users/${uid}`);

    try {
      await updateDoc(personnelRef, {
        workItems: arrayRemove(workItem?.id),
        projects: arrayRemove(workItem?.projectId),
      });
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description:
          'An error occurred while removing personnel from the work item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <Box>
      {editPersonnel && (
        <EditPersonnelForm
          personnel={editPersonnel}
          isOpen
          onClose={() => setEditPersonnel(undefined)}
        />
      )}

      {showSearch && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={6}
        >
          <PersonnelTableSearch onChange={handleSearch} />

          <Text color="gray.500" fontSize="sm">
            Showing {filteredPersonnel.length} of {personnel.length} results
          </Text>
        </Box>
      )}

      <TableContainer bg="white" rounded="md" shadow="md">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Trade</Th>
              <Th>Status</Th>
              <Th>Badge</Th>
              <Th>Rate</Th>
              {(showAssignment || workItems) && <Th>Assignment</Th>}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {filteredPersonnel.map((person) => (
              <Tr key={person.uid}>
                <Td display="flex" alignItems="center" gap={2}>
                  <Box alignItems="center" display="flex" gap={2}>
                    <PersonnelAvatar
                      id={person.uid}
                      name={`${person.firstName} ${person.lastName}`}
                      trade={person.trade}
                      size="sm"
                    />
                    <Box>
                      <Text fontWeight="medium">{`${person.firstName} ${person.lastName}`}</Text>
                      <Text color="gray.500" fontSize="xs">
                        {person.email}
                      </Text>
                    </Box>
                  </Box>
                </Td>
                <Td>
                  <TradeBadge trade={person.trade} />
                </Td>
                <Td>
                  <UserStatusBadge status={person.status} />
                </Td>
                <Td fontSize="sm">{person.badgeNumber}</Td>
                <Td fontSize="sm" textTransform="capitalize">
                  {person.rate}
                </Td>
                {showAssignment && (
                  <PersonnelAssignment projectIds={person.projects} />
                )}
                {workItems && (
                  <Td>
                    <PersonnelWorkItemAssignment
                      workItems={workItems}
                      workItemIds={person.workItems}
                    />
                  </Td>
                )}
                <Td>
                  <Box
                    alignItems="center"
                    display="flex"
                    gap={2}
                    justifyContent="flex-end"
                  >
                    {showRemoveAction && (
                      <Button
                        size="xs"
                        colorScheme="red"
                        onClick={() => handleRemove(person.uid)}
                      >
                        Remove
                      </Button>
                    )}
                    {showEditAction && (
                      <Button
                        leftIcon={<Edit />}
                        size="xs"
                        variant="outline"
                        onClick={() => setEditPersonnel(person)}
                      >
                        Edit
                      </Button>
                    )}
                    {isAdmin ? <OptionsMenu user={person} /> : <Box />}
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
