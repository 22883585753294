import { ItemStatus, IWorkItem } from '../../../types';

type GroupedWorkItems = {
  inProgress: IWorkItem[];
  notStarted: IWorkItem[];
  suspended: IWorkItem[];
  complete: IWorkItem[];
};

export function groupWorkItemsByStatus(
  workItems: IWorkItem[],
  searchValue: string,
): GroupedWorkItems {
  return workItems
    .filter((workItem) => workItem.number.includes(searchValue))
    .reduce<GroupedWorkItems>(
      (acc, workItem) => {
        switch (workItem.status) {
          case ItemStatus.IN_PROGRESS:
            acc.inProgress.push(workItem);
            break;
          case ItemStatus.NOT_STARTED:
            acc.notStarted.push(workItem);
            break;
          case ItemStatus.SUSPENDED:
            acc.suspended.push(workItem);
            break;
          case ItemStatus.COMPLETE:
            acc.complete.push(workItem);
            break;
          default:
            break;
        }

        return acc;
      },
      {
        inProgress: [],
        notStarted: [],
        suspended: [],
        complete: [],
      },
    );
}
