import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { Search } from '../../../assets';

interface Props {
  onChange: (value: string) => void;
}

export default function SearchInput({ onChange }: Props): JSX.Element {
  function handleChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    onChange(currentTarget.value);
  }

  return (
    <InputGroup size="sm">
      <InputLeftElement pointerEvents="none">
        <Search color="gray.300" />
      </InputLeftElement>
      <Input
        bg="white"
        placeholder="Search"
        rounded="md"
        onChange={handleChange}
      />
    </InputGroup>
  );
}
