import { Box, Heading, Text } from '@chakra-ui/react';
import { AnchorIcon } from '../../assets';

interface IProps {
  description?: string;
  heading?: string;
  width?: string | number;
}

export default function EmptyState({
  description,
  heading = 'No Results Found',
  width = 350,
}: IProps): JSX.Element {
  return (
    <Box>
      <Box
        textAlign="center"
        bg="gray.50"
        border="2px solid"
        borderColor="gray.300"
        shadow="md"
        rounded="md"
        p={8}
        w={width}
        m="auto"
      >
        <Box
          p={2}
          border="2px solid"
          borderColor="navyBlue"
          rounded="full"
          display="inline-block"
          mb={4}
        >
          <AnchorIcon fontSize="3xl" color="navyBlue" />
        </Box>
        <Heading size="sm" fontWeight="medium" mb={2}>
          {heading}
        </Heading>
        {description && (
          <Text color="gray.600" fontSize="sm">
            {description}
          </Text>
        )}
      </Box>
    </Box>
  );
}
