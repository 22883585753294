import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { doc, getDoc } from 'firebase/firestore';
import { AdminUser, PersonnelUser } from '../types';
import { db } from '../firebase';

type User = AdminUser | PersonnelUser;
type ReturnValue = [User | null, boolean];

export default function useFetchUser(uid: string): ReturnValue {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();

  useEffect(() => {
    async function fetchUser() {
      const usersCollection = doc(db, 'users', uid);

      try {
        const userDoc = await getDoc(usersCollection);

        if (userDoc.exists()) {
          setUser({
            uid: userDoc.id,
            ...userDoc.data(),
          } as User);
        }
      } catch (error) {
        toast({
          title: 'Uh Oh',
          description: 'Failed to retrieve user data. Please try again.',
          status: 'error',
          variant: 'left-accent',
          position: 'top-right',
        });
      }

      setIsLoading(false);
    }

    fetchUser();
  }, [uid]);

  return [user, isLoading];
}
