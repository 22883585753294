import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import {
  EmptyState,
  Loader,
  PageLayout,
  PersonnelForm,
  PersonnelTable,
} from '../../components';
import { PlusIcon } from '../../assets';
import { useFetchPersonnelUsers } from '../../hooks';

export default function PersonnelPage(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [personnelUsers, isLoading] = useFetchPersonnelUsers();

  return (
    <PageLayout>
      <PersonnelForm isOpen={isOpen} onClose={onClose} />

      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <Heading fontWeight="medium" fontSize="xl">
            Team
          </Heading>
          <Button
            leftIcon={<PlusIcon />}
            variant="primary"
            onClick={onOpen}
            size="sm"
          >
            Invite Teammate
          </Button>
        </Box>

        {isLoading && <Loader />}

        {!isLoading && personnelUsers.length === 0 && (
          <EmptyState
            description="Begin by creating new personnel."
            heading="No Personnel"
          />
        )}

        {personnelUsers.length > 0 && (
          <PersonnelTable
            personnel={personnelUsers}
            showAssignment
            showEditAction
          />
        )}
      </Box>
    </PageLayout>
  );
}
