import { Box } from '@chakra-ui/react';
import ActivityRow from './ActivityRow';
import { useActivitiesListenerByProject } from '../../../../hooks';
import { EmptyState } from '../../../../components';

interface Props {
  projectId: string;
}

export default function ActivitiesList({ projectId }: Props): JSX.Element {
  const [activities] = useActivitiesListenerByProject(projectId);

  if (activities.length === 0) {
    return (
      <Box>
        <EmptyState />
      </Box>
    );
  }

  return (
    <Box bg="white" display="grid" gap={2} rounded="md" shadow="md" p={[2, 4]}>
      {activities.map((activity) => (
        <ActivityRow key={activity.id} activity={activity} />
      ))}
    </Box>
  );
}
