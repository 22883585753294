import { useDisclosure, Button } from '@chakra-ui/react';
import { Edit } from '../../../../assets';
import { IWorkItem } from '../../../../types';
import { EditWorkItemForm } from '../../../../components';

interface Props {
  workItem: IWorkItem;
}

export default function EditWorkItemButton({ workItem }: Props): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <EditWorkItemForm workItem={workItem} isOpen={isOpen} onClose={onClose} />
      <Button leftIcon={<Edit />} size="sm" variant="outline" onClick={onOpen}>
        Edit Work Item
      </Button>
    </>
  );
}
