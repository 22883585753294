import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebase';
import { AdminUser } from '../types';

export * from './activities';
export * from './invitations';
export * from './tasks';
export * from './users';

export async function smsLoginPreflight(phoneNumber: string): Promise<void> {
  const smsLoginPreflight = httpsCallable<
    {
      phoneNumber: string;
    },
    void
  >(functions, 'smsLoginPreflight');

  await smsLoginPreflight({ phoneNumber });
}

type UpdateAdminUserParams = Omit<
  AdminUser,
  'uid' | 'companyId' | 'createdAt' | 'projects' | 'status'
>;

export async function updateAdminUser(
  updateParams: UpdateAdminUserParams,
): Promise<void> {
  const updateAdminUser = httpsCallable<{
    updateParams: UpdateAdminUserParams;
  }>(functions, 'updateAdminUser');

  await updateAdminUser({ updateParams });
}

export async function updateUserPhoneNumber(
  uid: string,
  phoneNumber: string | null,
): Promise<void> {
  const updateUserPhoneNumber = httpsCallable<
    {
      phoneNumber: string | null;
      uid: string;
    },
    void
  >(functions, 'updateUserPhoneNumber');

  await updateUserPhoneNumber({ phoneNumber, uid });
}
