import { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import { withdrawInvitation } from '../../../../api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  uid: string;
}

export default function ConfirmationModal({
  isOpen,
  onClose,
  uid,
}: Props): JSX.Element {
  const toast = useToast();
  const cancelRef = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleConfirm() {
    setIsLoading(true);

    try {
      await withdrawInvitation(uid);

      toast({
        title: 'Success',
        description: 'Invitation withdrawn.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error: any) {
      toast({
        title: 'Uh Oh',
        description: 'Failed to withdraw invitation. Please try again.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Withdraw Invitation
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to withdraw this invitation?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleConfirm}
              isLoading={isLoading}
              ml={2}
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
