import { useEffect, useState } from 'react';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import { Activity } from '../types';

export default function useActivitiesListenerByProject(projectId?: string) {
  const [activities, setActivities] = useState<Activity[]>([]);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    const activitiesCollection = collection(db, 'activities');
    const q = query(
      activitiesCollection,
      where('projectId', '==', projectId),
      orderBy('createdAt', 'desc'),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const activities: Activity[] = [];

      querySnapshot.forEach((doc) => {
        activities.push({
          id: doc.id,
          ...doc.data(),
        } as Activity);
      });

      setActivities(activities);
    });

    return () => unsubscribe();
  }, [projectId]);

  return [activities];
}
