import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, Text } from '@chakra-ui/react';
import { StatusBadge } from '../../../components';
import { useFetchTasksByWorkItem } from '../../../hooks';
import { ItemStatus, IWorkItem } from '../../../types';
import { workItemCompletionRatio } from '../../../utils';

interface Props {
  workItem: IWorkItem;
}

export default function WorkItemRow({ workItem }: Props): JSX.Element {
  const navigate = useNavigate();
  const { id, number, projectId, status } = workItem;
  const [tasks, isLoading] = useFetchTasksByWorkItem(id);
  const completedTaskCount = useMemo(
    () => tasks.filter((task) => task.status === ItemStatus.COMPLETE),
    [tasks],
  );
  const completionRatio = useMemo(
    () => workItemCompletionRatio(tasks),
    [tasks],
  );

  function handleClick() {
    navigate(`/projects/${projectId}/items/${id}`);
  }

  return (
    <Box
      onClick={handleClick}
      alignItems="center"
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="gray.200"
      cursor="pointer"
      display="grid"
      gridTemplateColumns="50px auto 200px"
      px={4}
      py={2}
      _hover={{ backgroundColor: 'gray.100' }}
    >
      <Box>
        <StatusBadge
          status={status}
          showText={false}
          size="xs"
          hasBorder={false}
        />
      </Box>

      <Box display="flex" alignItems="baseline" gap={0.5}>
        <Text color="gray.500" fontSize="2xs">
          #
        </Text>
        <Text fontSize="sm">{number}</Text>
      </Box>

      <Skeleton
        isLoaded={!isLoading}
        display="flex"
        alignItems="baseline"
        gap={2}
      >
        <Text fontSize="sm">
          <Text display="inline-block" mr={0.5}>
            {completionRatio}
          </Text>
          <Text as="span" color="gray.500" fontSize="2xs">
            %
          </Text>
        </Text>

        {tasks.length > 0 && (
          <Text color="gray.500" fontSize="2xs">
            {completedTaskCount.length} / {tasks.length} tasks completed
          </Text>
        )}
      </Skeleton>
    </Box>
  );
}
