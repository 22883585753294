import { useMemo } from 'react';
import { isOwnerUser } from '../../utils';
import { useFetchProjects } from '../../hooks';
import { useGetUser } from '../../contexts';
import { IProject, AdminUser, PersonnelUser } from '../../types';

export function useFetchProjectAssignments(): [IProject[], boolean] {
  const [projects, isLoading] = useFetchProjects();
  const user = useGetUser();
  const filteredProjects = useMemo(
    () => filterProjectsByAssignments(projects, user),
    [projects, user],
  );

  return [filteredProjects, isLoading];
}

export function filterProjectsByAssignments(
  projects: IProject[],
  user: AdminUser | PersonnelUser,
): IProject[] {
  if (isOwnerUser(user)) {
    return projects;
  }

  const { projects: userProjects } = user;

  return projects.filter((project) => userProjects.includes(project.id));
}
