import { useDisclosure, Button } from '@chakra-ui/react';
import { PlusIcon } from '../../../../assets';
import ActivityFormDrawer from '../../Activity/ActivityFormDrawer';

interface Props {
  projectId: string;
  workItemId: string;
}

export default function AddActivityButton({
  projectId,
  workItemId,
}: Props): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ActivityFormDrawer
        initialWorkItemId={workItemId}
        isOpen={isOpen}
        onClose={onClose}
        projectId={projectId}
      />
      <Button
        leftIcon={<PlusIcon />}
        size="sm"
        variant="outline"
        onClick={onOpen}
      >
        Add Replay
      </Button>
    </>
  );
}
