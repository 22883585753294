import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { Activity } from '../types';

type CreateActivityParams = Omit<Activity, 'id' | 'createdAt'>;

export async function createActivity(
  params: CreateActivityParams,
): Promise<Activity> {
  const activitiesCollection = collection(db, 'activities');
  const data = {
    createdAt: Timestamp.now(),
    ...params,
  };

  const { id } = await addDoc(activitiesCollection, data);

  return {
    id,
    ...data,
  } as Activity;
}
