import { useNavigate } from 'react-router-dom';
import { Box, Heading, useMediaQuery } from '@chakra-ui/react';
import {
  ProjectDetails,
  ProjectImage,
  ProjectProgressBar,
  StatusBadge,
} from '../../components';
import { IProject } from '../../types';

interface IProps {
  project: IProject;
}

export default function ProjectCard({ project }: IProps): JSX.Element {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  function handleClick() {
    const url = isMobile
      ? `/projects/${project.id}`
      : `/projects/${project.id}/replay`;

    navigate(url);
  }

  return (
    <Box
      shadow="md"
      rounded="md"
      py={6}
      px={5}
      bg="white"
      cursor="pointer"
      _hover={{ shadow: 'xl' }}
      onClick={handleClick}
    >
      <ProjectImage projectId={project.id} />

      <Heading size="xs" fontWeight="medium" mt={4} mb={4}>
        {project.name}
      </Heading>

      <Box mb={2} display="flex" alignItems="center" gap={2}>
        <StatusBadge status={project.status} size="xs" />
        <Box flexGrow="1">
          <ProjectProgressBar id={project.id} />
        </Box>
      </Box>

      <Box gap={3} alignItems="center" mb={5}></Box>

      <ProjectDetails project={project} />
    </Box>
  );
}
