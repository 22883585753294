import { MenuItem, Text, useDisclosure } from '@chakra-ui/react';
import { Close } from '../../../../assets';
import ConfirmationModal from './ConfirmationModal';

interface Props {
  uid: string;
}

export default function WithdrawInvitation({ uid }: Props): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ConfirmationModal isOpen={isOpen} onClose={onClose} uid={uid} />
      <MenuItem alignItems="center" display="flex" gap={2} onClick={onOpen}>
        <Close />
        <Text>Withdraw Invitation</Text>
      </MenuItem>
    </>
  );
}
