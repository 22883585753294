import { Box, FormLabel, Link, Skeleton, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useFetchWorkItem } from '../../../../hooks';

interface Props {
  projectId: string;
  workItemId: string;
}

export default function WorkItemLink({
  projectId,
  workItemId,
}: Props): JSX.Element | null {
  if (!workItemId) {
    return null;
  }

  const [workItem, isLoading] = useFetchWorkItem(workItemId);

  return (
    <Box>
      <FormLabel fontSize="2xs" mb={0.5} textColor="gray.500">
        Work Item
      </FormLabel>
      <Skeleton isLoaded={!isLoading} display="inline">
        {workItem && (
          <Link
            alignItems="baseline"
            as={ReactRouterLink}
            display="flex"
            to={`/projects/${projectId}/items/${workItemId}`}
            fontSize="xs"
          >
            <Text color="gray.500" fontSize="2xs">
              #
            </Text>
            {workItem.number}
          </Link>
        )}
      </Skeleton>
    </Box>
  );
}
