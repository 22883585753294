import { useState, SyntheticEvent, ChangeEvent } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Select,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Drawer from './Drawer';
import { useGetUser } from '../../contexts';
import { ref, uploadBytes } from 'firebase/storage';
import { inviteAdminUser } from '../../api';
import { storage } from '../../firebase';
import { AdminUser, UserRole } from '../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function AdminUserForm({ isOpen, onClose }: Props): JSX.Element {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<UserRole.ADMIN | UserRole.OWNER | undefined>(
    undefined,
  );
  const [title, setTitle] = useState<string>('');
  const [badgeNumber, setBadgeNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');

  const toast = useToast();
  const user = useGetUser();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!user || !role) {
      return;
    }

    setIsLoading(true);

    try {
      const adminUserData: Omit<AdminUser, 'uid' | 'createdAt' | 'status'> = {
        badgeNumber,
        companyId: user.companyId,
        email,
        firstName,
        lastName,
        projects: [],
        role,
        title,
      };

      const adminUser = await inviteAdminUser(adminUserData);

      if (file && adminUser) {
        const storageRef = ref(storage, `userImages/${adminUser.uid}`);

        try {
          await uploadBytes(storageRef, file);
        } catch (error) {
          toast({
            title: 'Uh Oh',
            description:
              'An error occurred while uploading the personnel image.',
            status: 'error',
            variant: 'left-accent',
            position: 'top-right',
          });
        }
      }

      toast({
        title: 'Success',
        description: `${firstName} ${lastName} has been invited.`,
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while inviting personnel.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.[0];

    if (fileUpload) {
      const imageSrc = URL.createObjectURL(fileUpload);

      setFile(fileUpload);
      setImagePreviewUrl(imageSrc);
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Invite Coach">
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
      >
        <VStack spacing={4} mb={6}>
          <Box display="flex" gap={4} w="100%">
            <FormControl isRequired>
              <FormLabel fontSize="sm">First Name</FormLabel>
              <Input
                placeholder="First Name"
                onChange={({ currentTarget }) =>
                  setFirstName(currentTarget.value)
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontSize="sm">Last Name</FormLabel>
              <Input
                placeholder="Last Name"
                onChange={({ currentTarget }) =>
                  setLastName(currentTarget.value)
                }
              />
            </FormControl>
          </Box>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Email</FormLabel>
            <Input
              placeholder="Email"
              onChange={({ currentTarget }) => setEmail(currentTarget.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Role</FormLabel>
            <Select
              onChange={({ currentTarget }) =>
                setRole(currentTarget.value as UserRole.ADMIN | UserRole.OWNER)
              }
            >
              <option value={undefined}>Select Role</option>
              <option value={UserRole.OWNER}>Head Coach</option>
              <option value={UserRole.ADMIN}>Assistant Coach</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Title</FormLabel>
            <Input
              placeholder="Title"
              onChange={({ currentTarget }) => setTitle(currentTarget.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Badge Number</FormLabel>
            <Input
              type="number"
              placeholder="Badge Number"
              onChange={({ currentTarget }) =>
                setBadgeNumber(currentTarget.value)
              }
            />
          </FormControl>

          <FormControl>
            <FormLabel>Profile Picture</FormLabel>
            <Input type="file" onChange={handleImageUpload} />
          </FormControl>

          {imagePreviewUrl && (
            <Box w="100%">
              <Image src={imagePreviewUrl} alt="logo-preview" maxHeight={200} />
            </Box>
          )}
        </VStack>

        <Box
          display="flex"
          gap={3}
          justifyContent="flex-end"
          marginTop="auto"
          pb={4}
        >
          <Button variant="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" isLoading={isLoading}>
            Send Invite
          </Button>
        </Box>
      </form>
    </Drawer>
  );
}
