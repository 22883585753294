import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IWorkItem } from '../types';

export default function useFetchWorkItem(
  id?: string,
): [IWorkItem | undefined, boolean] {
  const [workItem, setWorkItem] = useState<IWorkItem>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchWorkItems() {
      if (!id) {
        return;
      }

      const workItemRef = doc(db, `workItems/${id}`);
      const workItemDoc = await getDoc(workItemRef);

      setWorkItem({
        id: workItemDoc.id,
        ...workItemDoc.data(),
      } as IWorkItem);
      setIsLoading(false);
    }

    fetchWorkItems();
  }, [id]);

  return [workItem, isLoading];
}
