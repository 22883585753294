import { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { StatusBadge } from '../../../components';
import { ItemStatus, IWorkItem } from '../../../types';
import { ChevronDown, ChevronUp } from '../../../assets';
import WorkItemRow from './WorkItemRow';

interface Props {
  status: ItemStatus;
  workItems: IWorkItem[];
}

export default function WorkItemsGroup({
  status,
  workItems,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <Box>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.400"
        display="flex"
        justifyContent="space-between"
        px={2}
        py={1}
        _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box alignItems="center" display="flex" gap={1}>
          <StatusBadge
            fontWeight="semibold"
            hasBorder={false}
            status={status}
          />

          <Text color="gray.500" fontSize="xs">
            {workItems.length} {workItems.length === 1 ? 'item' : 'items'}
          </Text>
        </Box>

        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </Box>

      <Box h={isOpen ? 'inherit' : 0} overflow="hidden">
        {workItems.map((workItem) => (
          <WorkItemRow key={workItem.id} workItem={workItem} />
        ))}
      </Box>
    </Box>
  );
}
