import { useParams } from 'react-router-dom';
import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import ActivitiesList from './ActivitiesList';
import ActivityFormDrawer from './ActivityFormDrawer';
import { PlusIcon } from '../../../assets';
import { useGetUser } from '../../../contexts';
import { isAdminUser } from '../../../utils';

export default function Activity(): JSX.Element {
  const { id } = useParams();
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <ActivityFormDrawer isOpen={isOpen} onClose={onClose} projectId={id!} />

      <Box
        mb={6}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading fontWeight="medium" fontSize="xl">
          Replay
        </Heading>

        {isAdmin && (
          <Button
            leftIcon={<PlusIcon />}
            size="sm"
            variant="primary"
            onClick={onOpen}
          >
            Add Replay
          </Button>
        )}
      </Box>

      <ActivitiesList projectId={id!} />
    </Box>
  );
}
