import { useState, SyntheticEvent, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  useToast,
  VStack,
} from '@chakra-ui/react';
import CurrentProjectAssignments from './CurrentProjectAssignments';
import Drawer from '../Drawer';
import { updateUserProjects } from '../../../api';
import { useFetchProjects } from '../../../hooks';
import { AdminUser, IProject, PersonnelUser } from '../../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user: AdminUser | PersonnelUser;
}

export default function ProjectAssignmentsFormDrawer({
  isOpen,
  onClose,
  user,
}: Props): JSX.Element {
  console.log('Rendering ProjectAssignmentsFormDrawer');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const [projects, isLoadingProjects] = useFetchProjects();
  const [userProjects, setUserProjects] = useState<IProject[]>([]);
  const [projectValue, setProjectValue] = useState<string>('default');

  useEffect(() => {
    const userProjects: IProject[] = [];

    if (user.projects.length === 0) {
      return;
    }

    projects.forEach((project) => {
      if (user.projects.includes(project.id)) {
        userProjects.push(project);
      }
    });

    setUserProjects(userProjects);
  }, [projects, user]);

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const projectIds = userProjects.map((project) => project.id);

      await updateUserProjects(user.uid, projectIds);

      toast({
        title: 'Success',
        description: `User's project have been updated.`,
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      handleClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: `An error occurred updating this user's projects.`,
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleAddProject({ target }: ChangeEvent<HTMLSelectElement>) {
    const projectId = target.value;

    if (projectId) {
      const project = projects.find((project) => project.id === projectId);
      const userProject = userProjects.find(
        (project) => project.id === projectId,
      );

      if (project && !userProject) {
        setUserProjects([...userProjects, project]);
      }
    }

    setProjectValue('default');
  }

  function handleRemoveProject(projectId: string) {
    const updatedProjects = userProjects.filter(
      (project) => project.id !== projectId,
    );

    setUserProjects(updatedProjects);
  }

  function handleClose() {
    setProjectValue('default');
    setUserProjects([]);
    onClose();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} title="Project Assignments">
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
      >
        <VStack spacing={4} mb={6}>
          <FormControl>
            <FormLabel>Add Project Assignment</FormLabel>
            <Select
              placeholder="Select Project"
              isDisabled={isLoadingProjects}
              onChange={handleAddProject}
              value={projectValue}
            >
              {isLoadingProjects && <option>Loading...</option>}
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <CurrentProjectAssignments
            projects={userProjects}
            onRemoveProject={handleRemoveProject}
          />
        </VStack>

        <Box
          display="flex"
          gap={2}
          justifyContent="flex-end"
          marginTop="auto"
          pb={4}
        >
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" isLoading={isLoading}>
            Save
          </Button>
        </Box>
      </form>
    </Drawer>
  );
}
