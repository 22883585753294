import { Box } from '@chakra-ui/react';
import AddActivityButton from './AddActivityButton';
import EditWorkItemButton from './EditWorkItemButton';
import WorkItemBreadcrumbs from '../../WorkItemBreadcrumbs';
import { useGetUser } from '../../../../contexts';
import { isAdminUser } from '../../../../utils';
import { IWorkItem } from '../../../../types';

interface Props {
  projectId: string;
  workItem?: IWorkItem;
}

export default function Header({ projectId, workItem }: Props): JSX.Element {
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);

  return (
    <Box
      mb={6}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <WorkItemBreadcrumbs
        projectId={projectId}
        workItemNumber={workItem ? workItem.number : undefined}
      />

      {isAdmin && workItem && (
        <Box display="flex" gap={1}>
          <EditWorkItemButton workItem={workItem} />
          <AddActivityButton projectId={projectId} workItemId={workItem.id} />
        </Box>
      )}
    </Box>
  );
}
