import { useState, SyntheticEvent, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Select,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useGetUser } from '../../contexts';
import { updateAdminUser } from '../../api';
import { AdminUser, UserRole } from '../../types';

export default function EditAdminProfileForm(): JSX.Element {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<UserRole>();
  const [title, setTitle] = useState<string>('');
  const [badgeNumber, setBadgeNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const user = useGetUser();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setRole(user.role);
      setTitle(user.title);
      setBadgeNumber(user.badgeNumber);
    }
  }, [user]);

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsLoading(true);

    if (!role) {
      return;
    }

    try {
      const updateParams: Omit<
        AdminUser,
        'uid' | 'companyId' | 'createdAt' | 'projects' | 'status'
      > = {
        firstName,
        lastName,
        email,
        role,
        title,
        badgeNumber,
      };

      await updateAdminUser(updateParams);

      toast({
        title: 'Success',
        description: 'Profile successfully updated.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while inviting personnel.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} mb={8}>
        <Box display="flex" gap={4} w="100%">
          <FormControl isRequired>
            <FormLabel fontSize="sm">First Name</FormLabel>
            <Input
              placeholder="First Name"
              onChange={({ currentTarget }) =>
                setFirstName(currentTarget.value)
              }
              value={firstName}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Last Name</FormLabel>
            <Input
              placeholder="Last Name"
              onChange={({ currentTarget }) => setLastName(currentTarget.value)}
              value={lastName}
            />
          </FormControl>
        </Box>

        <FormControl isRequired>
          <FormLabel fontSize="sm">Email</FormLabel>
          <Input
            placeholder="Email"
            onChange={({ currentTarget }) => setEmail(currentTarget.value)}
            value={email}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel fontSize="sm">Role</FormLabel>
          <Select
            onChange={({ currentTarget }) =>
              setRole(currentTarget.value as UserRole.ADMIN | UserRole.OWNER)
            }
            value={role}
          >
            <option value={undefined}>Select Role</option>
            <option value={UserRole.OWNER}>Head Coach</option>
            <option value={UserRole.ADMIN}>Assistant Coach</option>
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel fontSize="sm">Title</FormLabel>
          <Input
            placeholder="Title"
            onChange={({ currentTarget }) => setTitle(currentTarget.value)}
            value={title}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel fontSize="sm">Badge Number</FormLabel>
          <Input
            type="number"
            placeholder="Badge Number"
            onChange={({ currentTarget }) =>
              setBadgeNumber(currentTarget.value)
            }
            value={badgeNumber}
          />
        </FormControl>
      </VStack>

      <Box display="flex" justifyContent="flex-end">
        <Button type="submit" variant="primary" isLoading={isLoading}>
          Update Profile
        </Button>
      </Box>
    </form>
  );
}
