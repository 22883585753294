import { Box, useMediaQuery } from '@chakra-ui/react';
import { Routes, Route, useParams } from 'react-router-dom';
import Activity from './Activity';
import ProjectTeam from './ProjectTeam';
import WorkItemsList from './WorkItemsList';
import WorkItem from './WorkItem';
import ProjectSidebar from './ProjectSidebar';
import { PageLayout } from '../../components';

export default function ProjectPage(): JSX.Element {
  const { id } = useParams();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <PageLayout>
        <Routes>
          <Route index element={<ProjectSidebar projectId={id} />} />
          <Route path="items" element={<WorkItemsList />} />
          <Route path="items/:workItemId" element={<WorkItem />} />
          <Route path="team" element={<ProjectTeam />} />
        </Routes>
      </PageLayout>
    );
  }

  return (
    <PageLayout hasSidebar>
      <Box display="grid" gridTemplateColumns="250px auto">
        <ProjectSidebar projectId={id} />

        <Box
          py={4}
          px={6}
          display="grid"
          gridTemplateRows="min-content auto min-content"
        >
          <Routes>
            <Route index element={<WorkItemsList />} />
            <Route path="replay" element={<Activity />} />
            <Route path="items" element={<WorkItemsList />} />
            <Route path="items/:workItemId" element={<WorkItem />} />
            <Route path="team" element={<ProjectTeam />} />
          </Routes>
        </Box>
      </Box>
    </PageLayout>
  );
}
