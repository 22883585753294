import { memo, useMemo } from 'react';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import ProjectAssignments from './ProjectAssignments';
import ResendInvitation from './ResendInvitation';
import UpdateStatus from './UpdateStatus';
import WithdrawInvitation from './WithdrawInvitation';
import { isOwnerUser } from '../../../utils';
import { useGetUser } from '../../../contexts';
import { Options as OptionsIcon } from '../../../assets';
import { AdminUser, PersonnelUser, UserRole, UserStatus } from '../../../types';

interface Props {
  user: AdminUser | PersonnelUser;
}

function OptionsMenu({ user }: Props): JSX.Element | null {
  const { role, status, uid } = user;
  const currentUser = useGetUser();
  const isInvitationPending = status === UserStatus.PENDING;
  const showUpdateStatus = useMemo(() => {
    if (isInvitationPending) {
      return false;
    }

    if (isOwnerUser(user) && !isOwnerUser(currentUser)) {
      return false;
    }

    if (currentUser.uid === uid) {
      return false;
    }

    return true;
  }, [currentUser, isInvitationPending, role]);
  const showProjectAssignments = useMemo(
    () => isOwnerUser(currentUser) && role === UserRole.ADMIN,
    [currentUser, role],
  );

  const isMenuEmpty = getIsMenuEmpty(
    isInvitationPending,
    showProjectAssignments,
    showUpdateStatus,
  );

  if (isMenuEmpty) {
    return null;
  }

  return (
    <Menu>
      <MenuButton as={Button} display="inline" variant="ghost" size="sm">
        <OptionsIcon />
      </MenuButton>

      <MenuList>
        {isInvitationPending && <ResendInvitation uid={uid} />}
        {isInvitationPending && <WithdrawInvitation uid={uid} />}
        {showProjectAssignments && <ProjectAssignments user={user} />}
        {showUpdateStatus && <UpdateStatus user={user} />}
      </MenuList>
    </Menu>
  );
}

function getIsMenuEmpty(
  isInvitationPending: boolean,
  showProjectAssignments: boolean,
  showUpdateStatus: boolean,
): boolean {
  return !isInvitationPending && !showProjectAssignments && !showUpdateStatus;
}

export default memo(OptionsMenu);
